import * as React from "react";
import { Grid } from "@material-ui/core";
import {
  IDataProvider,
  ILeagueMetadata,
  IScoreboard,
  ITeamMatchup,
} from "../Interfaces/IDataProvider";
import ESPNDataProvider from "../Utilities/ESPNDataProvider";
import Matchup from "./Matchup";
import GenerateMatchupKey from "../Utilities/GenerateMatchupKey";

interface IProps {
  leagueMetadata: ILeagueMetadata;
  seasonId: number;
  weekId: number;
}

interface IState {
  data: IScoreboard;
  loading: boolean;
  error: string;
}

class Scoreboard extends React.Component<IProps, IState> {
  private dataProvider: IDataProvider;

  constructor(props: IProps) {
    super(props);
    this.dataProvider = new ESPNDataProvider({
      leagueMetadata: props.leagueMetadata,
      seasonId: props.seasonId,
      weekId: props.weekId,
    });
    this.state = {
      data: [],
      loading: true,
      error: "",
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  loadData = () => {
    this.setState({ loading: true });
    this.dataProvider.getScoreboard().then((scoreboard) => {
      this.setState({
        data: scoreboard,
        loading: false,
      });
    });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidMount() {
    this.loadData();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    const { loading, error } = this.state;
    if (loading) {
      return <p>Loading ...</p>;
    }
    if (error) {
      return (
        <p>
          There was an error loading the data. Please try again.{" "}
          <button onClick={this.loadData}>Retry</button>
        </p>
      );
    }

    const matchups: ITeamMatchup[] = this.state.data;

    console.log(this.state.data);
    return (
      <>
        <h1 className="LeagueTitle">{this.props.leagueMetadata.leagueTitle}</h1>
        <Grid container={true} spacing={4} style={{ paddingTop: 20, paddingBottom: 20 }}>
          {matchups.map((matchup) => (
            <Matchup matchup={matchup} key={GenerateMatchupKey(matchup)} />
          ))}
        </Grid>
      </>
    );
  }
}

export default Scoreboard;
