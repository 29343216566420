import * as React from "react";
import { Grid } from "@material-ui/core";
import Roster from "./Roster";
import { ITeamDetail } from "../Interfaces/IDataProvider";

const TeamDetail = ({ teamDetail }: { teamDetail: ITeamDetail }): JSX.Element => (
  <Grid item xs={12}>
    <Grid item xs={12} className="TeamName">
      <h2>{teamDetail.name}</h2>
    </Grid>
    <Roster team={teamDetail} />
  </Grid>
);

export default TeamDetail;
