import * as React from "react";
import Scoreboard from "./Components/Scoreboard";
import "./Assets/main.css";
import Constants from "./Config/Constants";

function App(): JSX.Element {
  const generateLeagueTitle = (index) => {
    return index === 0
      ? "A League"
      : "B League Bitches (feat. Sean - The Bottommost Bitch)";
  };
  return (
    <div className="App">
      {Constants.leagues.map((league, index) => (
        <div className="League" key={league.id}>
          <Scoreboard
            leagueMetadata={{ ...league, leagueTitle: generateLeagueTitle(index) }}
            seasonId={Constants.seasonId}
            weekId={Constants.weekId}
          />
        </div>
      ))}
    </div>
  );
}

export default App;
