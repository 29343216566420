export default {
  leagues: [
    {
      name: "A League",
      id: 653309,
      rosterSize: 9,
    },
    {
      name: "B League",
      id: 935921,
      rosterSize: 15,
    },
  ],
  seasonId: 2020,
  weekId: 13,
};
