import {
  IBoxScore,
  IEspnApiResponse,
  IRosterEntry,
  ITeam,
} from "../Interfaces/IEspnApiResponse";
import axios from "axios";
import {
  IDataProvider,
  ILeagueMetadata,
  IScoreboard,
  ITeamDetail,
} from "../Interfaces/IDataProvider";

interface IParams {
  leagueMetadata: ILeagueMetadata;
  seasonId: number;
  weekId: number;
}

class ESPNDataProvider implements IDataProvider {
  private apiUrl: string;
  private params: IParams;

  constructor(params: IParams) {
    // prettier-ignore
    this.apiUrl = `https://fantasy.espn.com/apis/v3/games/ffl/seasons/${
        params.seasonId
    }/segments/0/leagues/${
        params.leagueMetadata.id
    }?scoringPeriodId=${
        params.weekId
    }&view=modular&view=mNav&view=mMatchupScore&view=mScoreboard&view=mSettings&view=mTopPerformers&view=mTeam`;
    this.params = params;
  }

  private fetchData(): Promise<IEspnApiResponse> {
    return axios
      .get(this.apiUrl, {
        headers: {
          "x-fantasy-filter": `{"schedule":{"filterMatchupPeriodIds":{"value":[${this.params.weekId}]}}}`,
        },
      })
      .then((result) => {
        if (result.data && result.data.schedule) {
          return result.data;
        }
        return Promise.reject("invalid response");
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  }

  private getActiveRoster(rosterEntries: IRosterEntry[]): IRosterEntry[] {
    return rosterEntries
      .sort((a, b) => {
        return a.lineupSlotId - b.lineupSlotId;
      })
      .slice(0, this.params.leagueMetadata.rosterSize - 1);
  }

  private determineIfPlayerHasPlayed(boxScore: IBoxScore, playerId: number) {
    return (
      boxScore.rosterForMatchupPeriod.entries.find((entry) => {
        return playerId === entry.playerId;
      }) !== undefined
    );
  }

  private getTeamForId(teams: ITeam[], teamId: number): ITeam {
    return teams.find((team) => team.id == teamId);
  }

  private buildTeamDetail(team: ITeam, boxScore: IBoxScore): ITeamDetail {
    const roster = boxScore.rosterForCurrentScoringPeriod;
    const name = team.abbrev;
    const score = roster.appliedStatTotal || 0;
    const activeRosterPlayers = this.getActiveRoster(roster.entries);
    const players = activeRosterPlayers.map((entry) => {
      return {
        name: entry.playerPoolEntry.player.fullName,
        score: entry.playerPoolEntry.appliedStatTotal,
        projection: entry.playerPoolEntry.player.stats[0].appliedTotal || 0,
        hasPlayed: this.determineIfPlayerHasPlayed(boxScore, entry.playerId),
      };
    });
    const projection = boxScore.totalProjectedPointsLive;

    return {
      name,
      score,
      players,
      projection,
    };
  }

  getScoreboard(): Promise<IScoreboard> {
    return this.fetchData().then((response) => {
      return response.schedule.map((matchup) => {
        console.log(JSON.stringify(matchup));
        const homeBoxScore = this.buildTeamDetail(
          this.getTeamForId(response.teams, matchup.home.teamId),
          matchup.home
        );
        const awayBoxScore = this.buildTeamDetail(
          this.getTeamForId(response.teams, matchup.away.teamId),
          matchup.away
        );
        return [homeBoxScore, awayBoxScore];
      });
    });
  }
}

export default ESPNDataProvider;
