import * as React from "react";
import { Grid, Paper } from "@material-ui/core";
import TeamDetail from "./TeamDetail";
import { ITeamMatchup } from "../Interfaces/IDataProvider";

const Matchup = ({ matchup }: { matchup: ITeamMatchup }): JSX.Element => (
  <Grid item xs={12} md={6} lg={4}>
    <Paper className="Matchup">
      {matchup.map((teamDetail) => (
        <TeamDetail teamDetail={teamDetail} key={teamDetail.name} />
      ))}
    </Paper>
  </Grid>
);

export default Matchup;
