import * as React from "react";
import { Grid } from "@material-ui/core";
import { ITeamDetail } from "../Interfaces/IDataProvider";

interface IProps {
  team: ITeamDetail;
}

class Roster extends React.Component<IProps> {
  private team: ITeamDetail;

  constructor(props: IProps) {
    super(props);
    this.team = props.team;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    const items = this.team.players.map((player) => {
      return (
        <Grid item xs={12} key={player.name} className="Player">
          <div className="Name">{player.name}</div>
          <div className="Score">
            {(player.hasPlayed && player.score.toFixed(2)) || "---"}
          </div>
          <div className="Projection">({player.projection.toFixed(2)})</div>
        </Grid>
      );
    });

    return (
      <div>
        <div className="Total">
          <div className="Score">{this.team.score.toFixed(2)}</div>
          <div className="Projection">({this.team.projection.toFixed(2)})</div>
        </div>
        {items}
      </div>
    );
  }
}

export default Roster;
