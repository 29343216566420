import { ITeamMatchup } from "../Interfaces/IDataProvider";

const GenerateMatchupKey: (matchup: ITeamMatchup) => string = (matchup) => {
  let matchupKey = "";
  matchup.forEach((team) => {
    matchupKey += team.name;
  });
  return matchupKey;
};

export default GenerateMatchupKey;
